/*
 * max-width 576px
 */
@include media-breakpoint-down(sm) {
    .voicemail-greetings-profile-wrapper {
        flex-direction: column !important;

        .voicemail-grettings-buttons {
            padding-top: $spacer-xs;
            width: 100%;
            align-self: flex-start;
            button {
                flex: 1 auto;
            }
        }
    }

    .date-container {
        display: flex;
        flex-direction: column;
    }
    .date-container input {
        flex: 1 100%;
        width: 100% !important;
    }

    .date-container {
        flex-wrap: wrap;
    }

    #dateInput {
        width: 100% !important;
        margin: 0;
    }
}

/*
 * max-width 768px
 */
@include media-breakpoint-down(md) {
    .layout-type4 {
        grid-template-areas:
            'header'
            'nav';
    }

    .layout-type4 > ng-component {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
    }

    .responsive-layout3 {
        grid-template-rows: 1fr 1fr auto;
        grid-template-areas:
            'calls'
            'agents'
            'stat';
    }
    queue-agents ul li:first-child {
        border-top: 1px solid var(--border-color) !important;
    }

    .provisionStandAlone .w-xxl {
        width: 406px;
    }

    .wrapper-lg {
        padding: $spacer;
    }
    .w-sm-100 {
        width: 100% !important;
    }

    body,
    html {
        overflow-x: hidden !important;
    }

    // active-calls {
    //     .d-md-inline-grid {
    //         display: none !important;
    //     }
    // }
    .btn-responsive {
        width: 100%;
        display: block;
    }

    .btn-help-responsive {
        width: 10%;
        overflow: hidden;
        padding-left: 5px;
    }
    #help-btn-text {
        display: none;
    }

    .border-sm-0 {
        border: none;
    }
}

/*
 * max-width 992px
 */
@include media-breakpoint-down(lg) {
    .alertuser {
        left: 0;
        width: 100%;
        margin: 0;
        top: 30%;
    }
    .loadingOuter {
        left: 0 !important;
        margin: 0;
        width: 100%;
    }
    #chat-window app-chat-info-panel, phonebook-contact-info-panel {
        position: absolute !important;
        top: 0;
        bottom: 0;
        right: 0;
    }
}

/*
 * min-width 768px
 */
@include media-breakpoint-up(md) {
    .apps-content .apps-wrapper .apps-body .app-card {
        max-width: 450px;
    }
    .layout-type4 {
        grid-template-areas:
            'header header'
            'nav content';
        grid-template-columns: 290px 1fr;

        & > ng-component {
            overflow: hidden;
            grid-area: content;
        }
    }

    .responsive-layout3 {
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr auto;
        grid-template-areas:
            'calls agents'
            'stat agents';
    }

    .w-md-100 {
        width: 100%;
    }
    queue-agents {
        border-left: 1px solid var(--border-color);
        width: 300px;
        background: var(--background);
    }
    .provisionStandAlone .w-xxl {
        width: 460px;
    }
    .d-md-inline-grid {
        display: inline-grid !important;
    }
}
