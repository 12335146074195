// Color scheme (color are used in function, so they can not be variables)
$primary:       #0096D1;
$link-color:    #B5B5B5;
$secondary:     #B5B5B5;
$success:       #36B213;
$warning:       #FF9A0C;
$danger:        #E05B4A;
$dark:          #363535;
$nav-link-color: inherit;

$spacer: 1em; // 16px m-3
$spacer-xs: $spacer * .25; // 4px m-1
$spacer-s: $spacer * .5; // 8px m-2
$spacer-l: $spacer * 1.25; // 20px m-4
$spacer-xl: $spacer * 2; // 32px m-5
$spacer-xxl: $spacer * 3; // 48px m-6

$small-height: 450px;

// margins and paddings
$spacers: (
    0: 0,
    1: $spacer-xs,
    2: $spacer-s,
    3: $spacer,
    4: $spacer-l,
    5: $spacer-xl,
    6: $spacer-xxl
) !default;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    upmd: 826px,
    lg: 992px,
    xl: 1200px,
    upxl: 1382px,
    xxl: 1400px
) !default;

$body-color:        var(--text-primary);
$form-text-color:   var(--text-hint);
$body-bg:           var(--background);

$enable-shadows:    false;
$enable-negative-margins: true;

// Button
$input-btn-focus-box-shadow:  none;

$btn-focus-width:       0;
$btn-active-box-shadow: none;
$btn-border-radius-sm:  0;
$btn-border-radius-lg:  0;
$btn-link-color:        $body-color;

// Close dialog button
$btn-close-focus-shadow: none;

// Dropdown
$dropdown-spacer: 0px;
$dropdown-divider-margin-y: $spacer-xs;
$dropdown-item-padding-y: $spacer-xs;
$dropdown-item-padding-x: $spacer-s;
$dropdown-link-disabled-color: var(--text-disabled);

// Fonts
$font-family-sans-serif:  "Arial", "Helvetica Neue",Helvetica,sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-base:        $font-family-sans-serif;

$font-size-root:    14px;
$font-size-base:    1em; // 14px
$font-size-lg:      1.25em; // 20px
$font-size-md:      $font-size-base;
$font-size-sm:      13px;
$font-size-xs:      12px;
$line-height-base:  1.42857143; // 20/14
$small-font-size:   $font-size-sm;
$focus-ring-width:  1px;

$h1-font-size:  2.5em;
$h2-font-size:  2em;
$h3-font-size:  1.5em;
$h4-font-size:  1.25em;
$h5-font-size:  1.125em;
$h6-font-size:  1em;
$headings-font-weight: 700;

// Border
$border-radius:         0px;
$border-color:          var(--border-color);
$tile-border-radius:    4px;

// Card
$card-border-color: $border-color;
$card-bg:           var(--bg-primary);

// List group
$list-group-item-padding-y:         $spacer;
$list-group-item-padding-x:         $spacer-xs;
$list-group-action-hover-color:     inherit;
$list-group-action-active-color:    inherit;
$list-group-action-active-bg:       var(--bg-primary-hover);
$list-group-hover-bg:               var(--bg-primary-hover);
$list-group-bg:                     var(--bg-primary);
$list-group-border-color:           $border-color;
$list-group-color:                  inherit;
$list-group-active-color:           var(--text-secondary);
$list-group-active-border-color:    $primary;
$list-group-active-bg:              $primary;

// Navbar
$navbar-padding-y:      0;
$navbar-padding-x:      $spacer-s;
$nav-link-padding-y:    $spacer-s;
$nav-link-padding-x:    $spacer;

// Sidebar
$app-aside-folded-width:         50px;
$app-aside-folded-nav-height:    40px;

// Avatar
$standard-avatar-size:           50px;

// Page
$page-padding: $spacer-l;

// Dialog
$modal-content-color:       $body-color;
$modal-content-bg:          var(--bg-primary);
$modal-backdrop-bg:         var(--bg-tertiary);
$modal-backdrop-opacity:    .8;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $border-color;

// Link
$link-hover-color:      inherit;
$link-decoration:       none;
$link-hover-decoration: none;

// Table
$table-cell-padding:    $spacer-s $spacer;

// Nav tabs
$nav-tabs-link-active-color:        $body-color;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: $primary;
$nav-tabs-border-color:             $border-color;

// Hr
$hr-border-width:   1px;
$hr-border-color:   $border-color;
$hr-margin-y:       $spacer-s;

// Badge
$badge-padding-y:       $spacer-xs * 0.5;
$badge-padding-x:       $spacer-xs;
$badge-font-size:       11px;
$badge-font-weight:     bold;
$badge-border-radius:   4px;

// Forms
$input-color: $body-color;
$input-height: 32px;
$input-border-color: var(--input-border-color);
$input-bg: $body-bg;
$input-focus-bg: inherit;
$input-focus-color: inherit;
$input-focus-box-shadow: none;
$input-focus-border-color: $primary;
$input-placeholder-color: $secondary;
$input-disabled-bg: var(--bg-disabled);
$input-disabled-color: var(--text-disabled);
$input-group-addon-bg: var(--bg-input-addon);
$input-group-addon-hover-bg: var(--bg-input-addon-hover);
$input-margin-bottom: $spacer;
$input-padding-x: .75rem;

$form-file-button-hover-bg: $input-group-addon-hover-bg;

$component-active-color: $primary;
$component-active-bg: var(--bg-primary);
$form-check-input-active-filter:        none;
$form-check-label-cursor:               pointer;
$form-check-margin-bottom:              $input-margin-bottom;
$form-check-input-bg:                   var(--bg-primary);
$form-check-input-border:               1px solid $border-color;
$form-check-input-border-radius:        0;
$form-check-input-width:                16px;
$form-check-min-height:                 16px;
$form-check-input-checked-border-color: $border-color;
$form-check-input-focus-border:         $border-color;
$form-check-padding-start:              0;
$form-check-input-checked-bg-image:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><rect width='10' height='10' fill='#{$primary}' /></svg>");

$form-check-radio-border-radius:        50%;
$form-check-radio-checked-bg-image:     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'><circle r='5' fill='#{$primary}'/></svg>");

$form-switch-padding-start:         0;
$form-switch-color:                 #616060;
$form-switch-width:                 $form-check-input-width * 2;
$form-switch-height:                20px;
$form-switch-bg-image:              none;
$form-switch-border-radius:         $form-check-input-width;
$form-switch-transition:            none;

$form-switch-focus-color:           $input-focus-border-color !default;
$form-switch-focus-bg-image:        $form-switch-bg-image;

$form-switch-checked-color:         $component-active-color !default;
$form-switch-checked-bg-image:      none;
$form-switch-checked-bg-position:   right center !default;

$form-text-font-size:               $font-size-sm;

// Image thumbnail
$thumbnail-padding: 0;
$thumbnail-bg: transparent;
$thumbnail-border-width: 0;

// Tables
$table-variants: ();

// Fieldset
$legend-margin-bottom:  $spacer;
$legend-font-size:      $h4-font-size;
$legend-font-weight:    700;

// Tooltip can't use border, so we have to set dark mode always
$tooltip-color:         white;
$tooltip-bg:            #666666;
$tooltip-border-radius: 4px;

$breadcrumb-item-padding-x: 0.2rem;

$pagination-padding-y: 6px;
$pagination-padding-x: 12px;
$pagination-padding-y-sm: 3px;
$pagination-padding-x-sm: 6px;
$pagination-border-width: 0;
$pagination-margin-start: 0;
$pagination-color: var(--text-secondary);
$pagination-bg: #363535;
$pagination-hover-color: var(--text-secondary);
$pagination-hover-bg: #545353;
$pagination-active-color: var(--text-secondary);
$pagination-active-bg: var(--bg-secondary);
$pagination-focus-color: $pagination-hover-color;
$pagination-focus-bg: $pagination-hover-bg;
$pagination-focus-box-shadow: none;
$pagination-disabled-color: var(--text-disabled);
$pagination-disabled-bg: var(--bg-disabled);
