[data-theme="dark"], .lights-out {
    --background: #545353;
    --text-primary: #FFFFFF;
    --text-primary-inverse: #111111;
    --bg-primary: #424242;
    --bg-primary-darken: #3b3b3b;
    --bg-primary-active: #6C6C6C;
    --bg-primary-hover: #858585;
    --bg-console: #363535;
    --color-icon-primary: #FFFFFF;
    --bg-icon-primary-hover: #616060;
    --bg-disabled: #3C3C3C;
    --text-disabled: #999999;
    --text-hint: #CCCCCC;
    --border-color: #595959;
    --input-border-color: #595959;
    --bg-title-bar: var(--bg-tertiary);
    --bg-title-bar-active: var(--bg-tertiary-active);
    --bg-input-addon: rgba(128, 128, 128, .15);
    --bg-input-addon-hover: rgba(128, 128, 128, .3);
    --bg-table-group: rgba(56, 56, 56, 1);
    --bg-alert-gray: #F5F5F5;
    --bg-alert-gray-border: #CACACA;
    --banner: #F2DEDE;
    --bg-button-secondary: rgba(256, 256, 256, .05);
    --error: #f2434e;
    --success: #42a146;

    // bootstrap
    --bs-secondary-color: var(--text-disabled);
    --bs-body-color: var(--text-primary);
    --bs-body-bg: var(--background);
    --bs-border-color: var(--border-color);
}

