@import "bootstrap/scss/accordion";
@import "/src/styles/app/app.variables";

accordion {
    $active-panel-bg: $input-group-addon-bg;
    $hover-panel-bg: $input-group-addon-hover-bg;

    @extend .accordion;

    .panel {
        margin-bottom: 0;
        border: none;
    }

    .panel-heading {
        padding: 0;
        border: none;
        background: none;
    }

    .panel-body {
        border: 1px solid var(--border-color);
    }

    &.p-0 .panel-body {
        padding: 0;
    }

    .accordion-toggle > button {
        --bs-btn-border-color: var(--border-color);
        --bs-btn-hover-border-color: var(--border-color);
        --bs-btn-active-border-color: var(--border-color);
        --bs-btn-padding-x: var(--bs-card-cap-padding-x);
        --bs-btn-padding-y: var(--bs-card-cap-padding-y);

        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: $input-group-addon-bg;

        &:hover {
            background: $hover-panel-bg !important;
        }

        // arrow icon
        &::before {
            $icon-size: var(--btn-svg-size);

            flex-shrink: 0;
            width: $icon-size;
            height: $icon-size;
            content: "";
            background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#111'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"));;
            background-repeat: no-repeat;
            background-size: $icon-size;
            transition: transform .2s ease-in-out;
            transform: rotate(-90deg);

            [data-theme="dark"] &, .lights-out & {
                background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#FFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"));
            }
        }
    }

    accordion-group:not(:last-child) {
        .panel-body, .accordion-toggle > button {
            border-bottom: none;
        }
    }

    .panel-open .accordion-toggle > button {
        border-bottom: none;
        background: $active-panel-bg;

        &::before {
            transform: rotate(0);
        }
    }

    .accordion-toggle .chevron {
        transition: transform .2s ease-in-out;
    }

    .panel-open .accordion-toggle .chevron{
        transform: rotate(180deg);
    }

    .no-border {
        .panel-collapse {
            .panel-body {
                border: none !important;
                padding: 0px;
            }
        }
    }


    /** options view - bold headers, chevrons last, no borders */
    &.accordion-options {
        .panel-body {
            border: none;
        }

        .accordion-toggle > button {
            flex-direction: row-reverse;
            justify-content: space-between;
            border: none;
            background: none !important;
            font-weight: bold;
            padding: 0;

            // arrow icon
            &::before {
                transform: rotate(180deg);
            }
        }

        .panel-open .accordion-toggle > button::before {
            transform: rotate(0);
        }
    }
}
