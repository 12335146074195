@import "../app/app.variables";

/* variables are declared in themes */
[data-theme], .lights-on, .lights-out {
    --ng-select-border-color: var(--input-border-color);
    --ng-select-bg-color: var(--background);
    --ng-select-text-color: var(--text-primary);
    --ng-select-open-border-color: var(--bg-secondary);
    --ng-select-placeholder-color: var(--text-tertiary);
}

.ng-select {
    // arrow
    .ng-arrow-wrapper {
        width: 25px;
        padding-right: 5px;

        .ng-arrow {
            pointer-events: none;
            display: inline-block;
            height: 0;
            width: 0;
            position: relative;
            border-color: var(--ng-select-text-color) transparent transparent;
            border-style: solid;
            border-width: 5px 5px 2.5px;
        }
    }

    &.ng-select-opened, &.ng-select-focused {
        --ng-select-border-color: var(--ng-select-open-border-color);
    }
    &.ng-select-opened {
        .ng-arrow-wrapper {
            .ng-arrow {
                top: -2px;
                border-color: transparent transparent var(--ng-select-text-color);
                border-width: 0 5px 5px;
            }
        }
        &:not([dropdownPosition="top"]):not([ng-reflect-dropdown-position="top"]) {
            .ng-select-container {
                border-bottom-color: transparent;
            }
        }
        &[dropdownPosition="top"], &[ng-reflect-dropdown-position="top"] {
            .ng-select-container {
                border-top-color: transparent;
            }
        }
    }

    ng-dropdown-panel {
        border-left-color: var(--ng-select-open-border-color);
        border-right-color: var(--ng-select-open-border-color);
        border-bottom-color: var(--ng-select-open-border-color);

        .ng-option .ng-secondary-text {
            opacity: .7;
        }

        .ng-option.ng-option-disabled {
            cursor: default;
            color: var(--text-disabled);
        }
    }

    &.ng-select-disabled, fieldset:disabled & {
        --ng-select-bg-color: var(--bg-disabled);
        --ng-select-text-color: var(--text-disabled);

        pointer-events: none;
        cursor: default;

        .ng-arrow-wrapper {
            opacity: .7;
        }
    }

    .ng-select-container {
        color: var(--ng-select-text-color);
        border: 1px solid var(--ng-select-border-color);
        min-height: $input-height;
        align-items: center;
        cursor: default;
        display: flex;
        outline: none;
        position: relative;
        width: 100%;
        border-radius: 0;
        background-color: var(--ng-select-bg-color);

        ::placeholder,
        :-ms-input-placeholder,
        ::-ms-input-placeholder {
            color: var(--ng-select-placeholder-color);
        }

        .ng-value-container {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 105px;
            overscroll-behavior: contain;
            scroll-snap-type: y proximity;
            display: flex;
            flex: 1;
            align-items: center;
            padding: 0 $input-padding-x;
            .ng-input {
                padding-left: 10px;
                padding-right: 45px;
                & > input {
                    box-sizing: content-box;
                    border: 0;
                    box-shadow: none;
                    outline: none;
                    width: 100%;
                    cursor: text;
                    background: transparent;
                    color: var(--ng-select-text-color);
                }
            }

            .ng-placeholder  {
                color: var(--ng-select-placeholder-color);
            }

            .ng-secondary-text {
                opacity: .7;
            }
        }
    }

    .ng-clear-wrapper {
        cursor: pointer;
        width: 17px;
        -webkit-user-select: none;
        user-select: none;
        position: relative;
        right: 0;
        .ng-clear {
            display: inline-block;
            font-size: 18px;
            line-height: 1;
            pointer-events: none;
        }
    }

    &.ng-select-single .ng-value {
        flex-grow: 1;
    }

    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                padding: 5px;
                gap: 5px;

                .ng-input {
                    z-index: initial !important;
                }

                .ng-value {
                    display: flex;
                    font-size: .9em;
                    background-color: var(--bg-primary-active);
                    border-radius: 2px;

                    .ng-value-label {
                        padding: 0 $spacer-xs;
                    }
                    .ng-value-icon {
                        cursor: pointer;
                        padding: 0 $spacer-xs;
                        height: 100%;

                        &:hover {
                            background-color: var(--bg-primary-hover);
                        }

                        .customSVGIcons svg {
                            fill: currentColor;
                        }

                        &.left {
                            border-right: 1px solid var(--border-color);
                        }

                        &.right {
                            border-left: 1px solid var(--border-color);
                        }

                        &:first-child {
                            border-top-left-radius: 2px;
                            border-bottom-left-radius: 2px;
                        }

                        &:last-child {
                            border-top-right-radius: 2px;
                            border-bottom-right-radius: 2px;
                        }
                    }
                }
            }
        }
    }

    &[dropdownPosition="top"], &[ng-reflect-dropdown-position="top"] {
        ng-dropdown-panel {
            border-top-color: var(--ng-select-open-border-color);
            border-bottom: 0;
        }
    }
}
