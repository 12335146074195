.bg {
    background: var(--background);
}

.bg-brand {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
}

.bg-tiles {
    background: var(--bg-primary);
}

.bg-red {
    background-color: var(--error);
    color: var(--text-secondary);
}

.bg-green {
    background-color: var(--status-available);
}
.no-bg {
    background-color: transparent;
    color: inherit;
}
.bg-console {
    background: var(--bg-console);
}

.status-indicator {
    background-color: var(--text-tertiary);

    &.on {
        background-color: var(--status-on);
    }

    &.off {
        background-color: var(--status-off);
    }

    &.off_queue {
        background-color: var(--status-off-queue);
    }

    &.busy {
        background-color: var(--status-busy);
    }

    &.available {
        background-color: var(--status-available);
    }

    &.away {
        background-color: var(--status-away);
    }

    &.out_of_office {
        background-color: var(--status-out-of-office);
    }

    &.custom1 {
        background-color: var(--status-custom1);
    }

    &.custom2 {
        background-color: var(--status-custom2);
    }

    &.custom3 {
        background-color: var(--error);
    }

    &.ringing {
        animation-name: blf-busy;
        animation-duration: .7s;
        animation-iteration-count: infinite;
    }
}
