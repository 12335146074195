@import "app.variables";

button span:not(:first-child) {
    line-height: initial;
}

.btn {
    --btn-svg-size: 12px;
    --btn-svg-fill: var(--color-icon-primary);
    --#{$prefix}btn-disabled-border-color: transparent;
    --#{$prefix}btn-hover-border-color: transparent;
    --#{$prefix}btn-active-border-color: transparent;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $spacer-s;
    white-space: nowrap;
    height: $input-height;

    svg {
        width: var(--btn-svg-size);
        height: var(--btn-svg-size);
        fill: var(--btn-svg-fill);
    }

    .show > &.dropdown-toggle {
        background-color: var(--#{$prefix}btn-active-bg);
        border-color: var(--#{$prefix}btn-active-border-color);
    }

    &.dropdown-toggle {
        &.menu-title, &.btn-plain, &.text-button {
            gap: $spacer-xs;
        }
        &::after {
            border-top: 0.4em solid;
            border-right: 0.4em solid transparent;
            border-left: 0.4em solid transparent;
        }
    }

    &:disabled, &.disabled, fieldset:disabled & {
        background-color: var(--#{$prefix}btn-disabled-bg) !important;
        border-color: var(--#{$prefix}btn-disabled-border-color) !important;
    }
}

.btn-plain, .btn-plain-sm, .btn-border, .btn-secondary {
    --#{$prefix}btn-hover-bg: var(--bg-icon-primary-hover);
    --#{$prefix}btn-active-bg: var(--bg-primary-active);
    --#{$prefix}btn-hover-border-color: var(--bg-icon-primary-hover);
    --#{$prefix}btn-active-border-color: var(--bg-primary-active);
}

.btn-border, .btn-secondary {
    --#{$prefix}btn-border-color: var(--border-color);
    --#{$prefix}btn-hover-border-color: var(--border-color);
    --#{$prefix}btn-active-border-color: var(--border-color);
    --#{$prefix}btn-disabled-border-color: var(--border-color);
    --btn-svg-size: 10px;
}

.btn-plain {
    --btn-svg-size: 17px;
}

.btn-plain-sm {
    --#{$prefix}btn-padding-x: 2px;
    --#{$prefix}btn-padding-y: 2px;
    --btn-svg-size: 14px;
}

.btn-primary, .btn-danger, .btn-gray {
    --#{$prefix}btn-color: var(--text-secondary);
    --#{$prefix}btn-hover-color: var(--text-secondary);
    --#{$prefix}btn-active-color: var(--text-secondary);
    --#{$prefix}btn-disabled-color: var(--text-secondary);

    --btn-svg-fill: var(--text-secondary);
    --btn-svg-size: 10px;
}

.btn-primary {
    --#{$prefix}btn-hover-border-color: var(--bg-secondary-hover);
    --#{$prefix}btn-hover-bg: var(--bg-secondary-hover);
    --#{$prefix}btn-active-border-color: var(--bg-secondary-hover);
    --#{$prefix}btn-active-bg: var(--bg-secondary-hover);
}

.btn-gray {
    --#{$prefix}btn-bg: var(--bg-tertiary-variant);
    --#{$prefix}btn-hover-bg: var(--bg-tertiary-active);
    --#{$prefix}btn-hover-border-color: var(--bg-tertiary-active);
    --#{$prefix}btn-active-bg: var(--bg-tertiary-active);
    --#{$prefix}btn-active-border-color: var(--bg-tertiary-active);
}

.btn-transparent-dark {
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-hover-bg: var(--bg-tertiary-active);
    --#{$prefix}btn-hover-border-color: var(--bg-tertiary-active);
    --#{$prefix}btn-active-bg: var(--bg-tertiary-active);
    --#{$prefix}btn-active-border-color: var(--bg-tertiary-active);
}

.btn-secondary {
    --#{$prefix}btn-bg: var(--bg-button-secondary);
    --#{$prefix}btn-color: var(--text-primary);
    --#{$prefix}btn-hover-color: var(--text-primary);
    --#{$prefix}btn-active-color: var(--text-primary);
    --#{$prefix}btn-disabled-color: var(--text-primary);

    --btn-svg-fill: var(--text-primary);
    --btn-svg-size: 10px;
}

.btn-close {
    align-self: flex-start;

    [data-theme="dark"] &, .lights-out & {
        @extend .btn-close-white;
    }
}

.btn-lg {
    height: auto;
}
