// Links
@import "../app/app.variables";

a {
    color: inherit;
    text-decoration: $link-decoration;

    &:hover {
        --#{$prefix}link-color-rgb: var(--#{$prefix}link-hover-color-rgb);
        text-decoration: $link-hover-decoration;
    }
}
