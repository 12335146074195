.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12),
        inset 0 0 0 1px var(--border-color);
    background: var(--bg-primary);
    opacity: .95;
    z-index: 1060 !important;
}

.cdk-drag-placeholder {
    visibility: hidden;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
