:root {
    --bg-secondary: #0096D1;
    --text-secondary: #FFFFFF;
    --bg-secondary-variant: #4fc3f7;
    --bg-tertiary: #363535;
    --text-tertiary: #B5B5B5;
    --bg-tertiary-variant: #484848;
    --bg-tertiary-active: #2F2C2C;
    --bg-tertiary-hover: #E9ECEF;
    --text-tertiary-hover: #333333;
    --bg-secondary-hover: #00719E;
    --error: #E05B4A;
    --bg-info: #CCE5FF;
    --text-info: #004085;
    --text-sender-chat: #333333;
    --bg-sender-chat: #DCF8C6;
    --scrollbar: #ADADAD;
    --make-call: #4ED964;
    --terminate-call: #FE3B31;
    --expired: #CB1A36;
    --active: #46CB1A;
    --maintenance: #E8B311;

    // Avatar Colors
    --status-on: var(--bg-secondary);
    --status-out-of-office: var(--error);
    --status-available: #36B213;
    --status-away: #FF9A0C;
    --status-busy: #FFFF00;
    --status-off: #808080;
    --status-off-queue: #E0E0E0;
    --status-custom1: #85FF5C;
    --status-custom2: #ffa0a0;
}
