ng-select.ng-touched.ng-invalid, ng-select.invalid {
    .ng-select-container {
        border-color: var(--error) !important;
    }
}

[formControlName],
[formControl],
[ngModel],
input,
textarea,
select {
    &.ng-touched.ng-invalid, &.invalid {
        &, & > .form-control, & > .input-group {
            border-color: var(--error) !important;
        }
    }
}

val-errors, .val-errors {
    color: var(--error);
}

.error-border {
    border: 1px solid var(--error) !important;
}

val-errors.prevent-shift {
    display: block !important;
    height: 1.25rem;
}

.red-circle-mark {
    position: relative;

    &:after {
        content: '⬤';
        color: var(--error);
        font-size: 7px;
        position: absolute;
        right: -8px;
    }
}

.value-accessor .form-control-validation {
    display: none;
}

.show-validation .value-accessor .form-control-validation,
.value-accessor .ng-touched .form-control-validation,
.value-accessor.ng-touched > .form-control-validation {
    display: block;
}

.show-validation .value-accessor .ng-invalid {
    .form-control, .input-group, .ng-select-container {
        border-color: var(--error);
    }
}
