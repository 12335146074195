@import "app.breakpoints";

/* container is hidden by default and is given style=display:block on show, which we want to make flex */
modal-container[style] {
    display: flex !important;
}

.modal-dialog {
    width: 100%;
}

.modal-content {
    .modal-title {
        font-weight: normal;
    }

    .cdk-drop-list-dragging :not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
    }
}

.file-preview-dialog {
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    margin: 0;

    .modal-content {
        background-color: var(--bg-tertiary);
        height: 100vh;
        max-height: 100vh;

        & > * {
            height: 100vh;
            max-height: 100vh;
        }
    }

    .modal-header, .modal-body {
        background-color: var(--bg-tertiary);
        color: var(--text-tertiary);
    }
}

.modal-body > *:last-child { margin-bottom: 0; }

/* be careful with overflow on dialogs containing dropdowns */
.scrollable .modal-body {
    max-height: calc(100vh - 12rem);
    overflow: auto;
}

.contains-inner-scrollable .modal-body {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 12rem);
}

.modal-component {
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    .modal-content {
        align-items: center;
    }

    .modal-content > * {
        width: 100%;
        max-width: 100%;
    }

    @include media-breakpoint-up(sm) {
        & {
            width: auto;
        }
        .modal-content > * {
            width: var(--bs-modal-width);
        }
    }
}
