bs-datepicker-container,
bs-daterangepicker-container {
    z-index: 1080;
}

.bs-datepicker-container {
    padding: 15px;
}

.bs-datepicker {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    background: var(--bg-primary);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .5);
    position: relative;
    z-index: 1;

    .bs-media-container {
        display: flex;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }
}

.bs-datepicker-head {
    min-width: 270px;
    height: 50px;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    text-align: justify;
    background-color: var(--bg-tertiary);
    &:after {
        content: "";
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }

    button {
        display: inline-block;
        vertical-align: top;
        padding: 0;
        height: 30px;
        line-height: 30px;
        border: 0;
        background: transparent;
        text-align: center;
        cursor: pointer;
        color: var(--text-secondary);
        transition: .3s;

        &.previous {
            span {
                transform: translate(-1px,-1px);
            }
        }
        &.next,
        &.previous {
            border-radius: 50%;
            width: 30px;
            height: 30px;

            span {
                font-size: 28px;
                line-height: 1;
                display: inline-block;
                position: relative;
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }
        }
        &.current {
            border-radius: 15px;
            max-width: 155px;
            padding: 0 13px;
        }

        &[disabled],
        &[disabled]:active,
        &[disabled]:hover {
            background: var(--bg-tertiary-variant);
            color: var(--bg-tertiary-hover);
            cursor: not-allowed;
        }
    }

}

.bs-datepicker-body {
    padding: 10px;
    border-radius: 0 0 3px 3px;
    min-height: 232px;
    min-width: 278px;
    border: 1px solid var(--border-color);

    table {
        width: 100%;
        border-collapse: initial;
        border-spacing: 0;

        th {
            font-size: 13px;
            color: var(--text-primary);
            font-weight: 400;
            text-align: center;
        }

        td {
            color: var(--text-primary);
            text-align: center;
            position: relative;
            padding: 0;

            &.is-highlighted:not(.disabled):not(.selected) span,
            span.is-highlighted:not(.disabled):not(.selected) {
                background-color: var(--bg-tertiary-hover);
                transition: 0s;
                color: var(--text-sender-chat);
            }
            &.disabled span,
            span.disabled {
                color: var(--text-tertiary);
            }
            &.is-other-month span,
            span.is-other-month {
                color: var(--text-tertiary);
            }
            &:before,
            span:before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                top: 6px;
                bottom: 6px;
                left: -3px;
                right: -2px;
                box-sizing: initial;
                background: transparent;
            }

            span {
                display: block;
                margin: 0 auto;
                font-size: 13px;
                border-radius: 50%;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
            &.selected span,
            span.selected {
                color: var(--text-primary);
                background-color: var(--bg-primary-active);
            }

            &.active {
                position: relative;

                &.select-start:before {
                    left: 35%;
                }

                &.select-end:before {
                    left: -85%;
                }
            }

            span.active.select-start:after,
            span.active.select-end:after,
            &.active.select-start span:after,
            &.active.select-end span:after {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                width: 100%;
                height: 100%;
                transition: 0.3s;
                top: 0;
                border-radius: 50%;
            }

            &:before,
            span:before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                top: 6px;
                bottom: 6px;
                left: -5px;
                right: -2px;
                box-sizing: content-box;
                background: transparent;
            }

            &.active.select-start + td.active:before {
                left: -20%;
            }

            &:last-child.active:before {
                border-radius: 0 3px 3px 0;
                width: 125%;
                left: -25%;
            }
        }

        /* .bs-datepicker-body table.days */
        &.days {
            td,
            span {
                &.active:not(.select-start):before,
                &.in-range:not(.select-start):before {
                    background: var(--bg-primary-hover);
                }
            }

            span {
                width: 32px;
                height: 32px;
                line-height: 32px;

                &.select-start {
                    z-index: 2;
                }
                &.is-highlighted.in-range:before {
                    right: 5px;
                    left: -5px;
                }
                &.in-range.select-end:before {
                    right: 6px;
                    left: 0;
                }

                &.today{
                    background-color: var(--text-tertiary);
                    color: var(--bg-primary);
                }
            }

            td {
                &.select-start + td.select-end:before,
                &.select-start + td.is-highlighted:before,
                &.active + td.is-highlighted:before,
                &.active + td.select-end:before,
                &.in-range + td.is-highlighted:before,
                &.in-range + td.select-end:before {
                    background: var(--bg-primary-hover);
                    width: 100%;
                }
            }
        }

        //&.days {
        //    span {
        //        width: 32px;
        //        height: 32px;
        //        line-height: 32px;
        //    }
        //}
        &.months {
            td {
                height: 52px;
                span {
                    padding: 6px;
                    border-radius: 15px;
                }
            }
        }
        &.years {
            td {
                span {
                    width: 46px;
                    height: 46px;
                    line-height: 45px;
                    margin: 0 auto;
                }
            }
            tr:not(:last-child) td span {
                margin-bottom: 8px;
            }
        }
        &:not(.weeks) tr td:first-child:before {
            border-radius: 3px 0 0 3px;
        }
        &:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
            cursor: pointer;
        }
    }

    .days {
        &.weeks {
            position: relative;
            z-index: 1;
        }
    }
}





