/** Convenient import for using bootstrap breakpoint mixins in component styles */
@import "./app.variables";
@import "bootstrap/scss/functions";
@import "../vendors/bootstrap.functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";


@include media-breakpoint-down(xxl) {
    .d-xxl-down-none, .xxl-visible {
        display: none;
    }
}

@include media-breakpoint-down(xl) {
    .d-xl-down-none, .xl-visible {
        display: none;
    }
}


@include media-breakpoint-down(lg) {
    .d-lg-down-none, .lg-visible {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .d-md-down-none, .md-visible {
        display: none;
    }
}
