svg { display: block; };

.customSVGIcons {
    flex: none;
    vertical-align: middle;
    display: inline-block;

    svg {
        flex: none;
        display: inline-block;
        fill: var(--color-icon-primary);
        vertical-align: top;
    }
}

app-spinner-regular-icon, [app-spinner-regular-icon] {
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

// Colors

.fillIconMsOfficial svg {
    fill: #eb3c00 !important;
}

.fillIconWithTertiary svg {
    fill: var(--text-tertiary);
}

.fillIconOrange svg {
    fill: var(--status-away) !important
}

.fillIconWhite svg {
    fill: var(--text-secondary) !important;
}

.fillIconGreen svg {
    fill: var(--status-available) !important;
}

.fillIconYellow svg {
    fill: var(--status-busy) !important;
}

.fillIconNoteColor svg {
    fill: var(--text-info) !important;
}

.fillIconWithPrimary svg {
    fill: var(--text-primary) !important;
}

.fillIconBlueVariant svg {
    fill: var(--bg-secondary-variant) !important;
}

.fillIconBlue svg {
    fill: var(--bg-secondary) !important;
}

.fillIconRed svg {
    fill: var(--error) !important;
}

.fillIconDisabled svg {
    fill: var(--bs-secondary-color) !important;
}

// Sizes

.svg-xxxs,
.svg-xxxs svg {
    width: 6px !important;
    height: 6px !important;
}

.svg-xxs,
.svg-xxs svg {
    width: 10px !important;
    height: 10px !important;
}

.svg-xs,
.svg-xs svg {
    width: 12px !important;
    height: 12px !important;
}

.svg-sm,
.svg-sm svg {
    width: 17px !important;
    height: 17px !important;
}

.svg-md,
.svg-md svg {
    width: 18px !important;
    height: 18px !important;
}

.svg-lg,
.svg-lg svg {
    width: 23px !important;
    height: 23px !important;
}

.svg-xl,
.svg-xl svg {
    width: 38px !important;
    height: 38px !important;
}

.svg-xxl,
.svg-xxl svg {
    width: 45px !important;
    height: 45px !important;
}
