.right-toast-placeholder {
    grid-area: 2/1/2/3;
    z-index: 1020;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;

    .arrow-container {
        height: 388px;
        min-width: 250px;
    }
}

.toasterName {
    span {
        display: inline-block;
    }
    white-space: nowrap;
    margin: 0;
    display: grid;
    &::-webkit-scrollbar {
        display: none;
    }
}
