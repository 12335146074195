[data-theme="light"], .lights-on {
    --background: #FCFCFC;
    --text-primary: #111111;
    --text-primary-inverse: #FFFFFF;
    --bg-primary: #FFFFFF;
    --bg-primary-darken: #f1f1f1;
    --bg-primary-active: #D1D1D1;
    --bg-primary-hover: #E7E6E6;
    --bg-console: #DDDDDD;
    --color-icon-primary: #808080;
    --bg-icon-primary-hover: #E6EBED;
    --bg-disabled: #E0E0E0;
    --text-disabled: #444444;
    --text-hint: #606060;
    --border-color: #ECECEC;
    --input-border-color: #e6e6e6;
    --bg-title-bar: var(--background);
    --bg-title-bar-active: var(--bg-icon-primary-hover);
    --bg-input-addon: rgba(128, 128, 128, .05);
    --bg-input-addon-hover: rgba(128, 128, 128, .15);
    --bg-table-group: #f3f3f3;
    --bg-alert-gray: #F5F5F5;
    --bg-alert-gray-border: #CACACA;
    --banner: #731615;
    --bg-button-secondary: rgba(0, 0, 0, .05);
    --success: #29792d;

    // bootstrap
    --bs-secondary-color: var(--text-disabled);
    --bs-body-color: var(--text-primary);
    --bs-body-bg: var(--background);
    --bs-border-color: var(--border-color);

}
