@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes busy {
    0% {
        background-color: #999900;
    }
    50% {
        background-color: #ffff00;
    }
    100% {
        background-color: #999900;
    }
}

//BORDER
@keyframes busy-border {
    0% {
        border-left-color: #999900;
    }
    50% {
        border-left-color: #ffff00;
    }
    100% {
        border-left-color: #999900;
    }
}

//BLF indicator
@keyframes blf-busy {
    0% {
        background-color: #64000b;
    }
    50% {
        background-color: #ff0017;
    }
    100% {
        background-color: #64000b;
    }
}