@import "bootstrap/scss/mixins";

@mixin dashedLink {
    text-decoration: none;
    color: var(--bg-secondary-variant);
    border-bottom: dashed 1px var(--bg-secondary-variant);
    cursor: pointer;
}

/** bold text without layout shift, requires letter-spacing: 0.03ex; */
@mixin bold-text() {
    text-shadow: -0.02ex 0 0 currentColor, 0.02ex 0 0 currentColor;
    -webkit-text-stroke-width: 0.03ex;
}
