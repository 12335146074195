/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */

// Themes
@import "./themes/themes";

// Variables
@import './app/app.variables';

// Vendors
@import './vendors/bootstrap';
@import "./vendors/bs-datepicker";
@import './vendors/ng-select';
@import './vendors/emoji-picker';

// Application
@import './app/app.scss';


