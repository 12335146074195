:root {
    --ng-select-option-height: 32px;
    --ng-select-option-padding-y: 6px;
    --ng-select-option-padding-x: 12px;
}

.dropdown-menu [role=menuitem].disabled { opacity: .7; }

.ng-dropdown-panel {
    background: var(--background);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border-color);

    .ng-dropdown-panel-items {
        .ng-option {
            padding: var( --ng-select-option-padding-y) var( --ng-select-option-padding-x);
            height: var(--ng-select-option-height);
            line-height: calc(var(--ng-select-option-height) - var( --ng-select-option-padding-y)*2);

            &.ng-option-selected {
                background-color: var(--bg-primary-active);
            }
            &.ng-option-marked {
                background-color: var(--bg-icon-primary-hover);
                color: var(--text-primary);
            }
            .btn {
                height: auto;
            }
        }
    }
}

.ng-select[groupBy] .ng-dropdown-panel .ng-dropdown-panel-items {
    .ng-option[role="option"] {
        padding-left: 24px;
    }

    .ng-optgroup .ng-option {
        font-weight: bold;
        cursor: default;
    }
}

.dropdown-divider {
    border-color: var(--text-tertiary);
    opacity: .5;
}

.dropdown {
    .side-menu {
        max-width: 300px;
        background-color: var(--bg-tertiary);
        position: absolute;
        left: 100%;
        top: 0;

        li {
            .side-item {
                position: relative;
                color: var(--text-tertiary);
                svg {
                    fill: var(--text-tertiary);
                }
                &:hover {
                    background-color: var(--text-tertiary);
                    color: var(--text-tertiary-hover);

                    span {
                        svg {
                            fill: var(--text-tertiary-hover);
                        }
                    }
                }

                .badge {
                    font-size: 11px;
                    padding: 2px 5px;
                }
            }
        }
    }
}

select:not(.list-box) {
    option {
        background: var(--background);
    }
}

.dropdown-menu {
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 0px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    border: none;
    font-size: 14px;
    background: var(--bg-primary);
    font-family: Arial, Helvetica, sans-serif !important;

    [role="menuitem"] {
        cursor: pointer;
        &.disabled {
            cursor: auto !important;
        }
    }

    .active {
        webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .status-menu-item {
        display: flex;
        align-items: center;

        .presence {
            flex: 0 0 auto;
            display: inline-block;
            width: 8px;
            height: 8px;

            &.big {
                margin: 0;
                width: 13px;
                height: 13px;
            }
        }
    }

    &.show {
        display: block;
        z-index: 999999999999;
    }

    a {
        color: var(--text-primary);
    }

    > li {
        > a, button, app-select-file-button {
            color: var(--text-primary);

            &:hover {
                background-color: var(--bg-primary-hover);
                color: var(--text-primary);
                transition: 0.2s;
            }
            strong {
                margin-top: 3px;
            }
        }

        &.active {
            > a, button {
                background-color: var(--bg-primary-hover);
                color: var(--text-primary);
                &:hover {
                    background-color: var(--bg-primary-hover);
                    color: var(--text-primary);
                    transition: 0.2s;
                }
            }
        }
    }

    > .dropdown-item {
        &:hover {
            background-color: var(--bg-primary-hover);
            color: var(--text-primary);
        }
    }

    &.black-menu {
        max-width: 300px;
        background-color: var(--bg-tertiary);

        .dropdown-item {
            color: var(--text-tertiary);

            svg {
                fill: var(--text-tertiary);
            }

            &:hover {
                span {
                    svg {
                        fill: var(--text-tertiary-hover);
                    }
                }
            }
            &:hover,
            &:focus {
                background: var(--text-tertiary);
                color: var(--text-tertiary-hover);

                .menu-nested-action {
                    background: var(--status-off);
                    svg {
                        fill: var(--text-tertiary-hover);
                    }
                }
            }
        }
    }
}

[dropdown] {
    position: relative;
}
