.list-group-item {
    svg {
        width: 17px;
        height: 17px;
        fill: var(--color-icon-primary);
    }

    &.focus svg {
        fill: var(--bg-secondary-hover);
    }

    &.active {
        .text-muted {
            color: var(--bg-secondary);
        }

        a {
            color: var(--text-secondary);
        }
    }

    .list-group-lg & {
        padding: $spacer $spacer-xs;
    }

    .list-group-sm & {
        padding: $spacer-xs $spacer-s;
    }

    .list-group.border-0 & {
        border: none;
    }

    .list-group.no-bg & {
        background-color: transparent;
    }
}

.list-group {
    --bs-list-group-item-padding-x: .5em;
}


