@import "app.variables";
@import "app.mixins";
@import "app.breakpoints";

tabset .nav-tabs {
    $gap: 1.5em;

    border-bottom-width: 1px;
    margin-bottom: $spacer;
    column-gap: $gap;

    @include media-breakpoint-down(sm) {
        margin-left: -$spacer;
        padding-left: $spacer;
        margin-right: -$spacer;
        padding-right: $spacer;
    }

    /* make tabs a one-liner with hidden horizontal scrollbar on touch screen */
    @media (pointer: coarse) {
        flex-wrap: nowrap;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
            height: 0;
        }

        scrollbar-width: none/*Firefox*/;
    }

    .nav-item {
        position: relative;

        &.invalid .nav-link:after {
            content: '\002B24';
            color: var(--error);
            font-size: 7px;
            position: absolute;
            top: var(--bs-nav-link-padding-x);
            right: var(--bs-nav-link-padding-y);
        }
    }

    .nav-link {
        min-height: 40px;
        display: flex;
        align-items: center;
        border: none;
        cursor: pointer;
        padding: 0 calc($gap/2);
        margin: 0 calc(-1*$gap/2);
        letter-spacing: 0.03ex;
        white-space: nowrap;
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        border-bottom: 2px solid var(--bg-secondary);
        padding: 0 2px;
        margin: 0 -2px;
        @include bold-text();
    }
}
