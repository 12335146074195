.avatar {
    width: $standard-avatar-size;
    height: $standard-avatar-size;
    flex: none;
    line-height: $standard-avatar-size;
    cursor: pointer;
    position: relative;
    display: flex;
    white-space: nowrap;

    .added-by-external {
        position: absolute;
        bottom: 0;
        right: 0;

        &.text-icon {
            color: var(--bg-tertiary-variant);
            background: var(--text-secondary);
            padding: 2px;
            font-size: 8px;
            font-weight: 700;
            line-height: 7px;
        }

        svg {
            width: 12px;
            height: 12px;
        }
    }

    img {
        display: block;
        object-fit: cover;
        height: 100%;
        max-width: 100%;
        width: 100%;
    }

    .avatar-circle {
        margin: 0;
        font-weight: 500;
        font-size: 1.2em;
        text-align: center;
        color: var(--text-secondary);
        width: 100%;
    }

    i {
        position: absolute;
        left: 25%;
        top: 20%;
        width: 8px;
        height: 8px;

        &.bottom {
            left: auto;
            top: auto;
            bottom: 0;
            right: 0;
        }

        &.left {
            top: auto;
            bottom: 0;
        }

    }
}

i {
    &.big {
        margin: 0;
        width: 14px;
        height: 14px;
    }
}

.extension-avatar {
    i {
        position: absolute;
        left: 25%;
        top: 20%;
        width: 8px;
        height: 8px;
        will-change: background-color;

        &.bottom {
            left: auto;
            top: auto;
            bottom: 0;
            right: 0;
        }

        &.big {
            margin: 0;
            width: 15px;
            height: 15px;
        }
    }
}

.caller-info-container,
.search-result {
    .avatar {
        width: 35px;
        height: 35px;
        line-height: 35px;

        i {
            &.big {
                margin: 0;
                width: 10px;
                height: 10px;
            }
        }

        .added-by-external {
            svg {
                width: 10px;
                height: 10px;
            }
            &.text-icon {
                padding: 1px;
            }
        }

    }
}
