@import "/src/styles/app/app.breakpoints";

.table {
    & > tbody,
    & > tfoot {
        & > tr {
            & > td,
            & > th {
                vertical-align: middle;
            }
        }
    }
}

.scrollable-table {
    // let the outer container scroll full page on small height
    @media (min-height: $small-height) {
        flex: 1 1 auto;
        overflow-y: auto;
        min-height: 0;
    }
}

    .scrollable-table.normal table, .scrollable-table.compact table, table.scrollable-table.normal, table.scrollable-table.compact {
        thead tr th {
            &:not(.checker):not(.icon):not(.actions) {
                width: auto;
            }
        }
    }

    .scrollable-table.compact table, table.scrollable-table.compact {
        tbody tr td {
            height: 30px;
            padding-right: 0.5rem;
            padding-top: 0;
            padding-bottom: 0;
            &:not(:first-child){
                padding-left: 0.5rem;
            }
        }
    }

    .scrollable-table table, table.scrollable-table {
        border-spacing: 0;
        width: 100%;
        table-layout: auto;
        /* should put cellSpacing=0 to table */
        border-collapse: unset;

        thead {
            position: sticky;
            inset-block-start: 0;
            z-index: 1;
        }

        tr {
            background: var(--bg-primary);

            &.odd {
                background: var(--bg-primary-darken);
            }
        }

        tbody tr {
            min-height: 50px;

            &.clickable {
                @extend .pointer;

                &:hover {
                    background-color: var(--bg-primary-hover);
                    transition: 0.1s;
                }

                &.active {
                    background-color: var(--bg-primary-hover) !important;
                    transition: 0.1s;
                }
            }

            &.warning {
                background: var(--background);

                td {
                    border-top: 1px solid var(--error);
                    border-bottom: 1px solid var(--error);

                    &:first-of-type {
                        border-left: 1px solid var(--error);
                    }

                    &:last-of-type {
                        border-right: 1px solid var(--error);
                    }
                }
            }
        }

        th, td {
            padding: $spacer-s $spacer-s;
            max-height: 100px;
            text-overflow: ellipsis;
        }

        &:not(.new-layout) {
            th, td {
                max-width: 300px;
            }
        }

        tr td:first-child,
        thead tr:first-child th:first-child {
            padding-left: $spacer-l;
        }

        tr td:last-child,
        thead tr:first-child th:last-child {
            padding-right: $spacer-l;
        }

        &:not(.new-layout) thead th {
            width: 20%;

            &.main {
                // column with main description, usually first or second if checker or icon presented
                width: 25%;
            }

            &.tiny-column {
                width: 5%;
            }

            &.small-column {
                width: 10%;
            }

            &.sm-icon-column {
                width: 20px !important;
            }

            &.datetime-column {
                max-width: 150px;
                width: 150px !important;
                min-width: 150px;
            }

            &.wide-column {
                width: 30%;
            }

            &.extra-wide-column {
                width: 50%;
            }
        }

        tbody td.actions:last-child {
            text-align: right;
        }

        thead th, tbody td {
            vertical-align: middle;
            border-bottom: 1px solid var(--border-color);
        }

        &:not(.new-layout) thead th, &:not(.new-layout) tbody td {

            &.checker, &.icon, &.actions {
                // checkbox column, usually first column
                // icon indicator column
                // column with action menu icon, usually at the end of the row
                width: 32px;
            }

            &.double-icon {
                width: 110px;
                min-width: 110px;
            }

            &.triple-icon {
                width: 165px;
                min-width: 165px;
            }
        }

        tfoot {
            position: sticky;
            inset-block-end: 0;
        }

        tr > td:only-child > .loader {
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: var(--bg-secondary);
            box-shadow: 32px 0 var(--bg-secondary), -32px 0 var(--bg-secondary);
            position: relative;
            animation: flash 0.5s ease-out infinite alternate;
        }

        @keyframes flash {
            0% {
                background-color: #FFF2;
                box-shadow: 32px 0 #FFF2, -32px 0 var(--bg-secondary);
            }
            50% {
                background-color: var(--bg-secondary);
                box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
            }
            100% {
                background-color: #FFF2;
                box-shadow: 32px 0 var(--bg-secondary), -32px 0 #FFF2;
            }
        }
    }

/*
 * Columns are marked by content type to select width; default is automatic.
 * Columns hiding should be done with classes d-md-down-none/d-lg-down-none/d-xl-down-none/d-xxl-down-none; default is always visible.
 */
table.new-layout {
    &.disabled {
        opacity: .7;
    }

    thead th {
        &.small-content, &.small-column {
            width: 200px;
            min-width: 100px;
        }

        &.email {
            width: 250px;
            min-width: 150px;
        }

        &.medium-content, &.medium-column, &.wide-column {
            width: 300px;
            min-width: 200px;
        }

        &.large-content, &.extra-wide-column {
            width: 800px;
            min-width: 200px;
        }

        &.icon, &.checker, &.actions {
            width: 32px;
            min-width: 32px;
        }

        &.double-icon {
            width: 110px;
            min-width: 110px;
        }

        &.triple-icon {
            width: 165px;
            min-width: 165px;
        }

        &.duration {
            width: 80px;
            min-width: 80px;
        }

        &.date {
            width: 150px;
            min-width: 150px;
        }

        &.datetime {
            width: 190px;
            min-width: 190px;
        }

        &.number, &.phone {
            width: 100px;
            min-width: 100px;
        }

        &.cost {
            width: 50px;
            min-width: 50px;
        }
    }
}

/** class expanded-column should be set to td */
table.one-expanded-column {
    --column-width: 100px;

    th, td {
        padding-right: $spacer;
        @include media-breakpoint-up(lg) {
            &:not(.checker):not(.actions):not(.icon):not(.text-break-spaces) {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        &:not(.expanded-column) {
            max-width: unset;
        }
    }

    td:not(.expanded-column):not(.checker):not(.actions):not(.icon) {
        width: var(--column-width);
    }

    td.expanded-column {
        max-width: var(--column-width);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.medium-columns {
        --column-width: 150px;
    }
}

table.print {
    background: white;
    color: #111111;
    font-size: 13px;
    table-layout: fixed;
    margin: 20px;

    tr.print {
        &.odd {
            background: #f1f1f1;
        }

        th, td {
            border-bottom: 1px solid black;
            border-right: 1px solid black;
            padding: 5px;
            font-size: 13px;

            &:first-child {
                border-left: 1px solid black;
            }
        }

        th {
            border-top: 1px solid black;

            &.large-content {
                width: auto;
            }
        }

        .inner-row td:first-child {
            padding-left: 20px;
        }
    }

    tfoot {
        text-align: right;
        font-size: 16px;
    }
}
