timepicker table {
    td { padding-top: 0; padding-bottom: 0; }

    /* hide timepicker control buttons */
    tr:first-child, tr:last-child {
        display: none;
    }

    /* hide excessive space in AM/MP buttons */
    .btn {
        --bs-btn-padding-x: 0;
    }
}
