@import "app.variables";
@import "bootstrap/scss/mixins/pagination";

@mixin smallPagingSize() {
    .pagination {
        @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, 0);

        .page-link-ellipsis {
            line-height: 1;
            font-size: 16px;
        }
    }

    .page-sizes {
        font-size: $font-size-sm;

        .ng-select-container {
            min-height: 24px;
        }
    }
}

.pagination {
    gap: $spacer-xs;

    // additional disabled styles
    .page-item.disabled .page-link {
        opacity: 0.4;
        cursor: default;
    }

    // prev and first, last and next sometimes change places; here we fix order
    .page-item {
        &.pagination-prev {
            order: 1;
        }

        &.pagination-first {
            order: 2;
        }

        &.pagination-page {
            order: 3;
        }

        &.pagination-last {
            order: 4;
        }

        &.pagination-next {
            order: 5;
        }
    }

    // icons view
    .page-item .customSVGIcons svg {
        fill: currentColor;
    }

    .page-link span {
        line-height: inherit;
    }

    // special view for first and last page template
    .pagination-first, .pagination-last {
        .page-link {
            padding: 0;
            display: flex;
            align-items: stretch;

            .page-link-content {
                padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
            }

            .page-link-ellipsis {
                font-size: 18px;
                color: var(--text-primary);
                background-color: var(--bg-primary);

                &:first-child {
                    padding-right: $spacer-xs;
                }

                &:last-child {
                    padding-left: $spacer-xs;
                }
            }
        }
    }
}

.pagination-container {
    &.small {
        @include smallPagingSize;
    }

    @include media-breakpoint-down(sm) {
        @include smallPagingSize;
    }
}
