@import "app.mixins";

.retrying, .processing-refresh {
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

// flex
.flex-1 { flex: 1 };
.flex-basis-100 {flex-basis: 100px;}
.flex-basis-150 {flex-basis: 150px;}

// Text
.text-xs { font-size: $font-size-xs;}
.text-sm {font-size: $font-size-sm;}
.text-md {font-size: $font-size-md;}
.text-lg {font-size: $font-size-lg;}

.text-ellipsis {
    @include text-truncate();

    display: block;
    float: left;
    margin-right: 10px;
}

// Grid
.grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 10px;
}

.d-inline-grid {
    display: inline-grid !important;
}

//Width
.maxw-0 {max-width: 0 !important;}
.mw-0 {min-width: 0;}
.w-auto {width: auto;}
.w-xxl { width: 360px;}
.w-fc {width: fit-content;}
.col-150 { width: 150px;}
.w-300 { width: 300px;}
.w-90 {width: 90%;}

// Margins

.negative-ml-12 { margin-left: -12px;}
.negative-ms-6 { margin-left: -6px; }

.m-t-n-xxs {margin-top: -1px;}

// Borders
.b-b {border-bottom: 1px solid var(--border-color);}
.b-r {border-right: 1px solid var(--border-color);}
.b-t {border-top: 1px solid var(--border-color);}
.b-l {border-left: 1px solid var(--border-color);}
.b-x {
    @extend .b-l;
    @extend .b-r;
}
.b-y {
    @extend .b-t;
    @extend .b-b;
}
// Wrappers
.wrapper-lg { padding: $spacer-xl }

// Links
.link, .link:hover {
    color: var(--bg-secondary);
    text-decoration: underline;
    cursor: pointer;
    &.dashed{
        text-decoration: none;
        border-bottom: dashed 1px var(--bg-secondary);
    }
    path {
        fill: var(--bg-secondary);
    }
}
// Generals
.cursor-default { cursor: default !important;}
.pointer { cursor: pointer;}
.cursor-not-allowed { cursor: not-allowed !important;}
.cursor-move { cursor: move;}
.text-underline { text-decoration: underline;}
.hidden-overflow {
    overflow: hidden;
}
.overflow-wrap-anywhere{
    overflow-wrap: anywhere;
}

.clear {
    display: block;
    overflow: hidden;
}

.tx-wrap {
    word-wrap: break-word;
    word-break: break-word;
}
.provisionStandAlone {
    color: var(--text-tertiary);

    ol {
        padding-left:40px;
    }
}
.wcToolbar {
    text-align: right;
    display: flex;
    align-items: center;
    min-height: 20px;
    fill:  var(--bg-tertiary-variant);
    color: var(--color-icon-primary);
    span.text-icon {
        color: var(--text-secondary);
        background: var(--bg-tertiary-variant);
        border-radius: 5px;
    }

    .btn {
        height: auto;
    }
}
.aside-align {
    height: $app-aside-folded-nav-height;
}

.aside-align-panel-header {
    min-height: $app-aside-folded-nav-height;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.message_in_the_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

.grid-group-item {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-left-width: 0;
    border-top-width: 0;
}
.img-thumbnail {
    height: 128px;
    width: 128px;
    min-width: 128px;
    display: flex;
    position: relative;
}
.conf-join-link {
    padding-bottom: 15px;
    a {
        color: var(--bg-secondary);
        text-decoration: underline;
    }
}
.ongoing-participants {
    vertical-align: top;
    margin: 5px 0;
}
.icon-spin {
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}

// Positions
.pos-abt {position: absolute;}
.pos-rlt {position: relative;}
.pos-stc {position: static !important;}
.pos-fix {position: fixed;}
// Hover
.mhover {
    &:hover {
        background-color: var(--bg-primary-hover) !important;
        transition: 0.1s;
    }
    &.selected-tile:not(:hover), &.selected:not(:hover)  {
        background-color: var(--bg-primary-active);
    }
}

.thumb {
    img {
        height: auto;
        max-width: 100%;
        vertical-align: middle;
    }

    width: 50px;
    display: inline-block
}

.text-info {
    color: var(--bg-secondary) !important;
}

.text-error {
    color: var(--error);
}

.alert {
    font-size: 13px;
    border-radius: 0;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    .close i {
        font-size: 12px;
        font-weight: normal;
        display: block;
    }
    p {
        margin: 0;
    }
}

.dashed_link,
.dashed_container a {
    @include dashedLink();
}

.hover-visible svg {
    fill: var(--text-tertiary);
}

.text-muted {
    color : var(--color-icon-primary) !important;
}

.show-more-tile{
    height: 50px;
    text-decoration: underline;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}


.two-column {
    column-count: 2;
    column-gap: $spacer * 1.5;
}

.one-column-md {
    @include media-breakpoint-down(md) {
        column-count: 1;
    }
}

.one-column-sm {
    @include media-breakpoint-down(sm) {
        column-count: 1;
    }
}

fieldset.disabled legend,
label.disabled,
p.disabled {
    opacity: 0.5;
}

label.flex-basis-200 {
    flex: 0 200px;
}

.hide-empty:empty {
    display: none;
}

.subheader {
    margin-top: $spacer-s;
    font-weight: normal;
    text-shadow: -0.02px 0 0 currentColor, 0.02px 0 0 currentColor;
    -webkit-text-stroke-width: 0.02px;
    letter-spacing: 0.03ex;
}

.col-gap-3 {
    column-gap: $spacer;
}

.col-gap-4 {
    column-gap: $spacer-l;
}

.col-gap-5 {
    column-gap: $spacer-xl;
}

.text-keep-lines {
    white-space: pre-line !important;
}
