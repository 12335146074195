ol.circle-numbers.primary {
    list-style-type: none;
    padding-left: 0;

    li {
        counter-increment: step-counter;
        margin-bottom: $spacer;
        display: grid;
        grid-template-columns: 24px minmax(0, 1fr);
        align-items: baseline;
        gap: $spacer-s;

        &::before {
            content: counter(step-counter);
            border-radius: 50%;
            color: var(--text-secondary);
            background: var(--bg-secondary);
            text-align: center;
            height: 24px;
            width: 24px;
            display: block;
            line-height: 24px;
        }
    }
}
