html, body, #root {
    width: 100%;
    height: 100%;
}

body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    cursor: default;
}

*:focus {
    outline: 0 !important;
}

.notransition * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}

select,
select option {
 border: 0;
 border-radius: 0;
}

// reset ol,ul,dl
ol, ul, dl {
    margin: 0;
    padding: 0;
}

* {
    // firefox
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: #0000;
    position: absolute;
}

::-webkit-scrollbar-corner {
    background-color: initial;
}

::-webkit-scrollbar-thumb {
    background-color:  var(--scrollbar);
}

::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0 !important;
    text-align: start !important;
    text-align-last: start !important;
}
