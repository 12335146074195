@import "app.mixins";

input {
    &:not(.pw-login) {
        &:-webkit-autofill,
        input:-webkit-autofill:focus {
            -webkit-transition: background-color 600000s 0s, color 600000s 0s;
            transition: background-color 600000s 0s, color 600000s 0s;
            -webkit-box-shadow: 0 0 0 1000px var(--background) inset !important;
            -webkit-text-fill-color: var(--text-primary) !important;
        }
    }

    /* Hide input number arrows: Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /* Hide input number arrows: Firefox */
    &[type="number"] {
        -moz-appearance: textfield;
    }

    &.form-control[readonly] {
        &, &:focus, &:active {
            border-color: var(--border-color);
            background: var(--bg-primary);

            &:not(.pointer) {
                cursor: default;
            }
        }
        .readonly-as-disabled & {
            background: var(--bg-disabled);
            opacity: .5;
        }
    }
}

.form-control-validation > *, val-errors.form-control-validation {
    display: block;
    font-size: $form-text-font-size;
    margin: $spacer-xs 0 0;
}

.form-control-validation:empty {
    display: none;
}

.form-input {
    display: block;
    margin-bottom: $input-margin-bottom;

    .form-label[for] {
        cursor: pointer;
    }

    .form-label {
        margin-bottom: 0;
    }

    .form-text {
        display: block;
        margin-top: $spacer-xs;
    }

    &.disabled, fieldset:disabled & {
        .form-label, .form-text, .input-group-append .input-group-text {
            cursor: default;
            opacity: 0.5;
        }
    }

    /* asterisk should not shift layout on appearing / disappearing -> visibility */
    label.form-label, .form-label > label {
        @include text-truncate();
        &:after {
            content: "*";
            color: var(--error);
            visibility: hidden;
        }
    }
    &.required {
        label.form-label:after, .form-label > label:after {
            visibility: visible;
        }
    }

    .input-group {
        height: $input-height;

        .input-group-append {
            display: flex;
            align-items: stretch;
            background: var(--background);
            z-index: 1;
        }

        & > *:first-child {
            flex-grow: 1;
            min-width: 0;
        }
    }
}

.form-check {
    label {
        cursor: pointer;
        user-select: none;
    }

    .form-check-input {
        flex-shrink: 0;
        cursor: pointer;
        margin: 0;

        &[disabled], &:disabled, fieldset:disabled & {
            & ~ .form-text, & ~ label {
                cursor: default;
                opacity: 0.5;
            }
        }
    }

    &.disabled, fieldset:disabled & {
        .form-text, label {
            cursor: default;
            opacity: 0.5;
        }
    }
}

// set the same image for unchecked state and alter the size for smooth transition of state change
.form-check:not(.form-switch) .form-check-input {
    transition: background-size .2s;

    /* instead of switching background image, we switch background image size */
    &:not(:checked):not(:indeterminate) {
        background-size: 0 0;
        background-image: escape-svg($form-check-input-checked-bg-image);
        &[type="radio"] {
            background-image: escape-svg($form-check-radio-checked-bg-image);
        }
    }

    &:indeterminate {
        border-color: var(--border-color);
    }

    &:not(:disabled) {
        background-color: var(--background);
    }

    &:checked {
        background-size: 8px 8px;
    }
}

.form-check:not(.form-switch) .form-check-label {
    line-height: $form-check-min-height;
}

.form-check.form-switch {
    position: relative;
    $icon-width: $form-switch-border-radius;
    min-height: $form-switch-height;

    .form-check-input {
        background-color: var(--bg-icon-primary-hover);
        height: $form-switch-height;
    }

    .form-check-label {
        line-height: $form-switch-height;
    }

    // using internal element for a possibility to use custom icon instead of inner circle
    .switch-icon {
        position: absolute;
        top: 2px;
        left: 3px;
        right: auto;
        width: $icon-width;
        height: $icon-width;
        padding: 4px;
        box-sizing: border-box;
        background-color: white;
        border-radius: 50%;
        fill: var(--text-disabled);
        box-shadow: 0 1px 3px rgba(#121621, .1);
        transition: left .4s ease, fill .4s ease;
    }

    .form-check-input:checked + .switch-icon {
        left: $form-switch-width - $icon-width - 3px;
        fill: $primary;
    }

}

legend {
    padding: $spacer-s 0 0;
    float: none;
    font-size: $font-size-root;
    @extend .border-bottom;
}

fieldset:disabled, .disabled {
    p, legend, .form-label, h4, h5, h6 {
        opacity: 0.5;
    }
    a, button, label {
        pointer-events: none;
        cursor: default;
    }
}

[contenteditable="true"] {
    cursor: auto;
}

@mixin selectArrow($color) {
    background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='#{$color}' stroke='#{$color}' d='m2 5 6 6 6-6'/></svg>"));
}

select.form-control {
    @include selectArrow('#111');
    background-position: right .5rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    padding-right: calc(0.75rem + 12px);
    @extend .text-truncate;
    height: $input-height;

    &:disabled {
        @include selectArrow('#444');
    }

    [data-theme="dark"] &, .lights-out & {
        @include selectArrow('#fff');

        &:disabled {
            @include selectArrow('#999');
        }
    }
}

/* for fields with inline labels */
.d-flex > .input-group {
    width: auto;
    flex-grow: 1;
    flex-basis: 100px;
}

.dark-dialog {
    label, p {
        color: var(--text-secondary);
    }

    .form-control {
        background-color: var(--text-secondary);
        color: var(--text-tertiary-hover);
    }
}

ol {
    padding-left: $spacer;
}
