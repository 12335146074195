@import "/src/styles/app/app.variables";

// Layout 2
.layout-type2-content {
    width: 100%;
}
.layout-type2-header  {
    background: var(--background);
}
// Layout 3
.layout-type3-content {
    height: 100%;

    @media (max-height: $small-height) {
        // on very small height scroll all page instead of inner content blocks
        overflow-y: auto;
    }

    .card {
        border-radius: 0;
        border-color: var(--border-color);
        background: var(--bg-primary);
    }

    .card-header {
        background-color: inherit;
        border-color: var(--border-color);
        font-weight: 700;
    }
}
.standard-layout3 {
    grid-template-rows: 1fr 1fr auto;
    grid-template-areas:
        "calls"
        "agents"
        "stat";
}

.responsive-layout3,
.standard-layout3 {
    display: grid;
    height: 100%;
    background-color: var(--background);
    overflow: auto;
}
app-layout-type3 {
    .nav-menu {
        list-style: none;
        overflow-y: auto;
        border-right: 1px solid var(--border-color);
        margin-right: -1px;

        .list-group-item.aside-align.d-flex.align-items-center {
            --#{$prefix}list-group-item-padding-y: 0;
        }
    }

    .nav-menu .list-group-item {
        border-right: 0;
        border-top: 0;
    }

    .nav-menu .list-group-item.focus, [role='nav-menu'] [role='menuitem'] .dropdown-item.focus {
        background:var(--bg-primary-active);
    }
}

// Layout 4
.layout-type4 {
    position: relative;
    display: grid;
    overflow-x: hidden;
    grid-template-rows: auto 1fr;
    height: 100%;

    .layout-type4-header {
        grid-area: header;
        background: var(--bg-primary);
    }

    .layout-type4-nav {
        overflow: auto;
        grid-area: nav;
    }
}


.layout-inner-chat-content {
    width: 100%;
}
// Unorder List people-list.component.html, phonebook.component.html
.presenceContent {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.chat-messages-root {
    position: relative;

    ng-component {
        // 11 more than info panel has
        z-index: 11;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
